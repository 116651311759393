import handlePromotionBannerTracking from './gtm-tracking.js';

function promotionFrontBannerTracking() {
    const promotionCTAButton = document.getElementsByClassName('promotion-front-banner-cta-button');
    const promotionImage = document.getElementsByClassName('promotion-front-banner-image');
    const promotionLink = document.getElementsByClassName('promotion-front-banner-tnc-link');
    const promotionNext = document.getElementById('promotion-front-swiper-next');
    const promotionPrev = document.getElementById('promotion-front-swiper-prev');
    const promotionPaginationBullet = document.querySelectorAll('#promotion-front-pagination .swiper-pagination-bullet');
    const promotionPaginationContainer = document.getElementById('promotion-front-pagination');
    let i;

    for (i = 0; i < promotionCTAButton.length; i++) {
        promotionCTAButton[i].addEventListener('click', function () {
            handlePromotionBannerTracking(this);
        });
    }

    for (i = 0; i < promotionImage.length; i++) {
        promotionImage[i].addEventListener('click', function () {
            handlePromotionBannerTracking(this);
        });
    }

    for (i = 0; i < promotionLink.length; i++) {
        promotionLink[i].addEventListener('click', function () {
            handlePromotionBannerTracking(this);
        });
    }

    promotionNext.addEventListener('click', function () {
        handlePromotionBannerTracking(this);
    });

    promotionPrev.addEventListener('click', function () {
        handlePromotionBannerTracking(this);
    });

    for (i = 0; i < promotionPaginationBullet.length; i++) {
        promotionPaginationBullet[i].dataset.eventname = 'banner_pagination';
        promotionPaginationBullet[i].dataset.eventlabel = 'banner_pagination';
        promotionPaginationBullet[i].dataset.position = i+1;
        promotionPaginationBullet[i].dataset.playertier = promotionPaginationContainer.dataset.playertier;

        promotionPaginationBullet[i].addEventListener('click', function () {
            handlePromotionBannerTracking(this);
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    promotionFrontBannerTracking();
});

module.exports = { promotionFrontBannerTracking }
